import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, video } from '../content/about'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Offices from '../sections/about/Offices'
import Chiefs from '../sections/about/Chiefs'
import Awards from '../sections/about/Awards'
import Certificates from '../sections/about/Certificates'
import Csr from '../sections/about/Csr'
// import BlogEvents from '../sections/about/BlogEvents'
import Partners from '../sections/Partners'
import Video from '../components/Video'

const About = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/about.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        imageUrl={image.publicURL}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Video data={video} />
      <Chiefs />
      <Awards />
      <Certificates />
      <Offices />
      <Csr />
      {/* <BlogEvents /> */}
      <Partners />
    </Layout>
  )
}

export default About
