import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import BannerImage from '../../components/BannerImage'

const content = {
  title: {
    en: 'Management',
    es: 'Gestión',
    ro: 'Conducerea',
  },
  data: [
    {
      name: 'Marek Rostkowski',
      title: 'CEO OMIDA Group',
      desc: {
        en: 'He has been a part of the TFL industry since 2002. A graduate of MBA studies at the WSB University in Gdańsk. Co-founder and President of Omida Logistics Sp. z o.o. He is motivated by the ever continuing success in business. He believes that an important element of every person`s development is self-improvement, which is why is a mentor in the Columbus Foundation and a development program for high school students known as The Grade. As the leader of OMIDA Group, he supports activities aimed at the development of employee competence and integration. He is involved in many local initiatives. He lives by the motto that "by learning and applying your knowledge, you can solve every problem, overcome every obstacle and achieve every goal you set"',
        es: 'Desde 2002, ha estado asociado con la industria de TFL. Graduado de los estudios de MBA en la Universidad WSB en Gdańsk. Co-fundador y Presidente de Omida Logistics Sp. z o.o. Lo motivan los éxitos sucesivos. Cree que un elemento importante del desarrollo de todo ser humano es la posibilidad de superación personal, por lo que es mentor de la Fundación Colón y del programa de desarrollo de bachillerato Grado. Como director del Grupo Omida, apoya actividades para el desarrollo de las competencias y la integración de los empleados. Involucrado en muchas iniciativas locales. Se guía por el lema de que "aprendiendo y aplicando los conocimientos adquiridos, puede resolver cualquier problema, superar cualquier obstáculo y lograr cualquier meta que se proponga".',
        ro: 'El face parte din industria TFL din 2002. Absolvent al studiilor MBA la Universitatea WSB din Gdańsk. Co-fondator și președinte al Omida Logistics Sp. z o.o. El este motivat de succesul continuu în afaceri. El crede că un element important al dezvoltării fiecărei persoane este auto-îmbunătățirea, motiv pentru care este mentor în Fundația Columbus și un program de dezvoltare pentru elevii de liceu cunoscut sub numele de The Grade. În calitate de lider al Grupului Omida, el sprijină activități care vizează dezvoltarea competenței și integrării angajaților. Este implicat în multe inițiative locale. El trăiește după motto-ul că „prin învățarea și aplicarea cunoștințelor tale, poți rezolva orice problemă, poți depăși orice obstacol și poți atinge fiecare obiectiv pe care ți-l stabilești”.',
      },
      image: 'image1',
    },
    {
      name: 'Bartłomiej Glinka',
      title: 'CEO OMIDA Group',
      desc: {
        en: 'Graduate of the Faculty of Economics at the University of Gdansk. He graduated with a major in Maritime Trade. A graduate of MBA studies at the WSB University in Gdańsk. Member of the Expert Council of the University of Gdansk. Associated with the TFL industry for over 20 years. Since 2010, he has been successfully managing a group of companies Omida Logistics Sp. z o.o., a Pomeranian leader in the TFL industry employing over 500 employees. He is the founder of the Academy of Personal Development Columbus.',
        es: 'Graduado de la Facultad de Economía de la Universidad de Gdańsk. Se graduó con una maestría con especialización en Comercio Marítimo. Graduado de los estudios de MBA en la Universidad WSB en Gdańsk. Miembro del Consejo de Expertos de la Universidad de Gdańsk. Ha estado asociado con la industria de TFL durante más de 20 años. Desde 2010, dirige con éxito un grupo de empresas, Omida Logistics Sp. z o.o., líder de Pomerania en la industria de TFL, que emplea a más de 500 empleados. Es el fundador de la Academia de Desarrollo Personal de Columbus.',
        ro: 'Absolvent al Facultății de Economie a Universității din Gdansk. A absolvit cu specializarea Comerț Maritim. Absolvent al studiilor MBA la Universitatea WSB din Gdańsk. Membru al Consiliului de experți al Universității din Gdansk. Asociat cu industria TFL de peste 20 de ani. Din 2010, conduce cu succes un grup de companii Omida Logistics Sp. z o.o., un lider pomeranian în industria TFL, care angajează peste 500 de angajați. El este fondatorul Academiei de Dezvoltare Personală Columbus.',
      },
      image: 'image2',
    },
  ],
}

const Chiefs = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/about/marek-rostkowski.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/about/bartlomiej-glinka.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <>
      <Section top>
        <Headline
          h={2}
          title={content.title[lang]}
          //
          large
          gradient
          //
          // medium
          // uppercase
          // dimmed
          // center
        />
      </Section>
      <Section mobile bottom>
        {content.data.map(({ name, title, desc, image }, id) => (
          <BannerImage
            square
            title={name}
            subtitle={title}
            desc={desc[lang]}
            image={query[image]}
            key={id}
            h={3}
          />
        ))}
      </Section>
    </>
  )
}

export default Chiefs
