import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import { s } from '../../style'
import alt from '../../content/alt'

const content = {
  title: {
    en: 'Achievements',
    es: 'Logros',
    ro: 'Realizări ',
  },
  desc: {
    en: 'OMIDA Group can boast numerous achievements, i.e. the prestigious award of the Forbes 2019 monthly, or the Gazele Biznesu awards, awarded to the most dynamically developing small and medium-sized enterprises, which it received in 2014, 2015, 2016 and 2018.',
    es: 'OMIDA Group puede presumir de numerosos logros, uno de ellos por ejemplo el prestigioso premio de la publicación mensual Forbes 2019, o los premios Gazele Biznesu, otorgados a las pequeñas y medianas empresas con un desarrollo más dinámico, que recibió en 2014, 2015, 2016 y 2018.',
    ro: 'OMIDA Group poate presupune numeroase rezultate, unul dintre ei, de exemplu, un prestigios premiu de la publicația Forbes 2019, sau premiile Gazelele Biznesu, pentru firme mici și mijlocii, companii cu o dezvoltare dinamică, care a primit în 2014, 2015, 2016 și 2018.',
  },
  data: [
    { image: 'tsl23' },
    { image: 'tsl24' },
    { image: 'tsl25' },
    { image: 'tsl26' },
    { image: 'forbes2019' },
    { image: 'gazelebiznesu' },
    { image: 'gryf22' },
    { image: 'forbes23' },
    { image: 'gptw23' },
    { image: 'gptw24' },
  ],
}

const Awards = () => {
  const { lang } = useLangContext()

  const images = useStaticQuery(graphql`
    {
      forbes2019: file(
        absolutePath: { regex: "/images/about/awards/forbes2019.jpg/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gazelebiznesu: file(
        absolutePath: { regex: "/images/about/awards/gazelebiznesu.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      tsl23: file(absolutePath: { regex: "/images/about/awards/tsl23.svg/" }) {
        publicURL
        extension
      }
      tsl24: file(absolutePath: { regex: "/images/about/awards/tsl24.svg/" }) {
        publicURL
        extension
      }
      tsl25: file(absolutePath: { regex: "/images/about/awards/tsl25.svg/" }) {
        publicURL
        extension
      }
      tsl26: file(absolutePath: { regex: "/images/about/awards/tsl26.svg/" }) {
        publicURL
        extension
      }
      gryf22: file(
        absolutePath: { regex: "/images/about/awards/gryf_2022.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      forbes23: file(
        absolutePath: { regex: "/images/about/awards/forbes_2023.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gptw23: file(
        absolutePath: { regex: "/images/about/awards/gptw23.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gptw24: file(
        absolutePath: { regex: "/images/about/awards/gptw24.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
    }
  `)

  // console.log(images)

  return (
    <>
      <Section top bottom id='osiagniecia'>
        <Headline
          h={2}
          title={content.title[lang]}
          desc={content.desc[lang]}
          large
          gradient
        />
        <div css={sContainer}>
          {content.data.map((award, id) =>
            images[award.image].extension !== 'svg' ? (
              <GatsbyImage
                css={sImage}
                key={id}
                image={getImage(images[award.image].childImageSharp)}
                alt={award.image + alt}
                imgStyle={{ objectFit: 'contain' }}
              />
            ) : (
              <img
                css={[
                  sImage,
                  {
                    [s.xs]: {
                      maxWidth: 'calc(50vw - 2rem)',
                      maxHeight: 'calc(50vw - 2rem)',
                    },
                    [s.md]: {
                      maxWidth: 160,
                      maxHeight: 160,
                    },
                    [s.lg]: {
                      maxWidth: 230,
                      maxHeight: 230,
                    },
                  },
                ]}
                key={id}
                src={images[award.image].publicURL}
                alt={award.image + alt}
              />
            )
          )}
        </div>
      </Section>
    </>
  )
}

const sContainer = {
  display: 'grid',
  marginTop: '2rem',
  gridRowGap: '1rem',
  gridColumnGap: '1rem',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.md]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridRowGap: '0rem',
    gridColumnGap: '4rem',
  },
}

const sImage = {
  objectFit: 'contain',

  [s.lg]: {
    margin: '2rem',
  },
}

export default Awards
